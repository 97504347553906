import React, { useState } from "react";
import style from "../Style/Resources.module.css";
import Button from "../Common/Button";
// import MonthPicker from "../Misc/MonthPicker";
import { pastEvents, upcomingEvents } from "../Misc/eventData";

const EventCard = ({ data }) => (
  <div className={style.card}>
    <div className={style.imgResources}>
      <img src={data.image} alt="event" className={style.img} />
    </div>
    <div className={style.txtContainer}>
      <div className={style.txtEvent}>
        <h1>{data.title}</h1>
        <p>{data.date}</p>
      </div>
      <div className={style.eventTxt}>
        <p>{data.description}</p>
        <a
          href={data.path}
          className={style.seeMoreLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          See more details...
        </a>
      </div>
    </div>
  </div>
);

const Event = () => {
  const [event, setEvent] = useState("upcoming");

  const btnRegister = () => {
    window.open("https://rebrand.ly/TSEvolveCon2024", "_blank", "noopener,noreferrer");
  };
  

  const btnEventHandler = () => {
    // window.open("/", "_blank", "noopener,noreferrer");

    console.log("Event clicked!");
  };

  const handleEvent = (e) => {
    setEvent(e.target.value);
  };

  const eventData = event === "upcoming" ? upcomingEvents : pastEvents;

  return (
    <div className={style.container}>
      <div className={style.eventRegister}>
        <h1>
          Evolve Conference (2.0): <br />
          Innovate, Elevate, Accelerate <br />
          Women Shaping the Future of Technology
        </h1>
        <div className={style.eventBtn}>
          <Button
            className={"btnPry"}
            title={"Register"}
            btnEventHandler={btnRegister}
          />
          <Button
            className={"btnSec"}
            title={"See more"}
            btnEventHandler={btnEventHandler}
          />
        </div>
        <p>26th Oct, 2024</p>
        <p>Webinar Live on Microsoft Teams</p>
      </div>
      <div className={style.postEvent}>
        <div className={style.eventPost}>
          <div className={style.pastEvent}>
            <button
              value="upcoming"
              onClick={handleEvent}
              className={event === "upcoming" ? style.active : style.pastEvent}
            >
              Upcoming Events
            </button>
            <button
              value="past"
              onClick={handleEvent}
              className={event === "past" ? style.active : style.pastEvent}
            >
              Past Events
            </button>
          </div>
          <div>{/* <MonthPicker /> */}</div>
        </div>
        <div className={style.gridContainer}>
          {eventData.map((data, index) => (
            <EventCard key={index} data={data} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Event;
